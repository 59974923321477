import axios from "axios";
import React, { useEffect, useState } from "react";
// import ReactHtmlParser from 'react-html-parser;
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import MainDescription from "../../../components/mainDescription/MainDescription";

type hostel = {
  hostelId: number;
  hostelName: string;
  aboutHostel: string;
  isEnable: number;
  accommodation: number;
  bedding: number;
  furniture: number;
  bathroomFacilities: number;
  commonAreas: number;
  kitchenAndDining: number;
  laundryFacilities: number;
  wifi: number;
  security: number;
  cleaningAndMaintenance: number;
  studyRooms: number;
  recreationFacilities: number;
  gymOrFitnessCenter: number;
  access24x7: number;
  hostelWardens: number;
  canteenOrSnackShops: number;
  guestRooms: number;
  parkingFacilities: number;
  bicycleStorage: number;
  culturalAndRecreationalEvents: number;
  stationery: 0;
};

function CollegeHostel() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const [hostelData, setHostelData] = useState<hostel>();

  const fetchHostelData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/hostels/by-institute/${institute_id}`
      );
      setHostelData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchHostelData();
  }, []);

  return (
    <>
      {(hostelData?.aboutHostel !== "" && hostelData?.aboutHostel)  && (
        <>
          <MainDescription
            heading={hostelData?.hostelName}
            data={hostelData?.aboutHostel}
          />
          {/* <p>{parse("<h3 class='pt-md-5 text-theme'>Featured anurag Colleges</h3><p class='pt-1 pb-3 w-md-50'>Loremas asjdfasdfa sifjas jfas fajsd jas fjasdlkf alskdjfoasjglkam<br> foajsflgja sglas lkgdjas ldkgjalksjdglkasdj gasjdgljaslg</p>")}</p> */}
          {/* <div dangerouslySetInnerHTML={{__html: hostelData?.aboutHostel}}></div> */}
        </>
      )}

      {hostelData?.isEnable ? (
        <div className="row g-2 py-3" style={{ fontSize: "12px" }}>
          <p className="title text-theme">Hostel Facilities</p>
          {hostelData?.access24x7 ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/access 24 7.png")}
                  alt="Access 24/7"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Access 24/7</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.accommodation ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/accommodation.png")}
                  alt="Accommodation"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Accommodation</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.bathroomFacilities ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/bathroom_facilities.png")}
                  alt="Bathroom"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Bathroom</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.bedding ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/bedding.png")}
                  alt="Bedding"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Bedding</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.bicycleStorage ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/bicycle_storage.png")}
                  alt="Bicycle Parking"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Bicycle Parking</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.canteenOrSnackShops ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/canteen_or_snack_shops.png")}
                  alt="Canteen/Shops"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Canteen/Shops</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.cleaningAndMaintenance ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/cleaning_and_maintenance.png")}
                  alt="Cleaning & Maintenance"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Cleaning & Maintenance</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.commonAreas ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/common_areas.png")}
                  alt="Common Area"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Common Area</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.culturalAndRecreationalEvents ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/cultural_and_recreational_events.png")}
                  alt="Cultural Event"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Cultural Event</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.furniture ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/furniture.png")}
                  alt="Furniture"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Furniture</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.guestRooms ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/guest_rooms.png")}
                  alt="Guest Room"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Guest Room</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.gymOrFitnessCenter ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/gym_or_fitness_center.png")}
                  alt="Gym"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Gym</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.hostelWardens ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/hostel_wardens.png")}
                  alt="Warden"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Warden</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.kitchenAndDining ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/kitchen_and_dining.png")}
                  alt="Kitchen & Dining"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Kitchen & Dining</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.laundryFacilities ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/laundry_facilities.png")}
                  alt="Laundry"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Laundry</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.parkingFacilities ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/parking.png")}
                  alt="Parking"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Parking</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.recreationFacilities ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/recreation_facilities.png")}
                  alt="Recreation"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Recreation</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.security ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/security.png")}
                  alt="Security"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Security</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.stationery ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/stationery.png")}
                  alt="Stationery"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Stationery</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.studyRooms ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/study_rooms.png")}
                  alt="Study Rooms"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Study Rooms</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {hostelData?.wifi ? (
            <div className="col-2">
              <div className="text-center bg-light-theme border rounded-1 pt-3" style={{height: '116px'}}>
                <img
                  src={require("../../../images/Hostel-Facilities/wifi.png")}
                  alt="Wifi"
                  height={50}
                />
                <p className="text-center mb-0 py-2">Wifi</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <h3 className="py-3">Hostel is not available</h3>
      )}
    </>
  );
}

export default React.memo(CollegeHostel);