import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type ScholarshipData = {
  scholarShipId: number;
  scholarshipName: string;
  amount: number;
  scholarShipDetails: number;
  instituteCourseId: number;
  instituteProfileId: number;
};

function CollegeScholarships() {
  const baseURL: string = process.env.REACT_APP_BASE_URL || "";
  const { institute_id } = useParams();
  const [scholarshipData, setScholarshipData] = useState<ScholarshipData[]>();

  const fetchScholarshipData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/scholarships/by-institute/${institute_id}`
      );
      setScholarshipData(response.data);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchScholarshipData();
  }, []);

  return (
    <>
      {scholarshipData?.length ? (
        <div className="college_highlights py-3">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Scholarship Name</th>
                <th scope="col">Details</th>
              </tr>
            </thead>
            <tbody>
              {scholarshipData?.map((data) => {
                return (
                  <tr>
                    <td>{data?.scholarshipName}</td>
                    <td>{data?.scholarShipDetails}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        "Data is not available!"
      )}
    </>
  );
}

export default React.memo(CollegeScholarships);
